import Konva, {Stage} from "konva";
import borderTopLeft from '../src/img/icons/border-top-left.svg'
import borderTopRight from '../src/img/icons/border-top-right.svg'
import borderBottomRight from '../src/img/icons/border-bottom-right.svg'
import borderBottomLeft from '../src/img/icons/border-bottom-left.svg'

const editor = () => {
  const _isMobile = window.innerWidth <= 576
  const _widthCanvas = _isMobile ? window.innerWidth*0.9  : window.innerWidth*0.5;
  const _heightCanvas = _isMobile ? window.innerHeight*0.5  : window.innerHeight*0.8;
  const editorContainer = document.querySelector('.editor__left')
  editorContainer.setAttribute("style",`width:${_widthCanvas}px; height:${_heightCanvas}px`);

	const input = document.querySelector('#load-img');
	input.addEventListener('change', loadImage);
	function loadImage() {
    if (input.files.length === 0) {
      return
    }
		let file, fr, img;

		file = input.files[0];
		fr = new FileReader();
		fr.onload = createImage;
		fr.readAsDataURL(file);

		function createImage() {
			img = new Image();
			img.onload = imageLoaded;
			img.src = fr.result;
		}

		function imageLoaded() {
    
      let max = _widthCanvas > _heightCanvas ? _widthCanvas : _heightCanvas;
      let ratio = (this.width > this.height ? (this.width / max) : (this.height / max))
			let userImg = new Konva.Image({
        x: 0,
        y: 0,
        image: this,
        width: this.width > this.height ? _widthCanvas : this.width / ratio,
        height: this.width > this.height ? this.height / ratio : _heightCanvas
      });
      stage = new Konva.Stage({
        container: 'canvas-container',   // id of container <div>
        width: this.width > this.height ? _widthCanvas : this.width / ratio,
        height: this.width > this.height ? this.height / ratio : _heightCanvas
      });
      // layer.setAttr({width: 100, height: 100})
      // userImg.zIndex(-1)
      
      stage.add(layer);
      layer.add(userImg);
      layer.draw();


		}
	}

  let imgBorderTopLeft = new Image();
  imgBorderTopLeft.src = borderTopLeft;
  let imgBorderTopRight = new Image();
  imgBorderTopRight.src = borderTopRight;
  let imgBorderBottomRight = new Image();
  imgBorderBottomRight.src = borderBottomRight;
  let imgBorderBottomLeft = new Image();
  imgBorderBottomLeft.src = borderBottomLeft;

  // first we need to create a stage
  let stage = new Konva.Stage({
    container: 'canvas-container',   // id of container <div>
    width: _widthCanvas,
    height: _heightCanvas
  });
  let layer = new Konva.Layer();

 


// add the layer to the stage
stage.add(layer);

// draw the image
layer.draw();

const btnStickers = document.querySelectorAll('.editor__stickers button');
const stikerNodes = [];
for (let i = 0; i < btnStickers.length; i++) {
  stikerNodes.push({added: false, node: null, transformer: null})
}

btnStickers.forEach((btn, i) => {
  const imgSrc = btn.querySelector('img').src;
  btn.addEventListener('click', () => {
    if (stikerNodes[i].added) {
      btn.classList.remove('added');
      stikerNodes[i].node.destroy()
      stikerNodes[i].transformer.destroy()
      stikerNodes[i] = {added: !stikerNodes[i].added, node: null, transformer: null}
    } else {
      btn.classList.add('added');
      Konva.Image.fromURL(imgSrc, function (node) {
        node.setAttrs({
          x: 200,
          y: 50,
          scaleX: 1,
          scaleY: 1,
          cornerRadius: 20,
          draggable: true,
        });
        let transformer = new Konva.Transformer({
          nodes: [node],
          keepRatio: true,
          enabledAnchors: [
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
          ],
          anchorStroke: '',
          // anchorFill: '#ffffff',
          // anchorSize: 20,
          borderStroke: '#FFE65F',
          borderDash: [9, 9],
          centeredScaling: true,
          anchorStyleFunc: (anchor) => {
            anchor.fill('')
            anchor.height(25);
            anchor.width(25);

            
            anchor.fillPatternScale({x: 0.60, y: 0.60})
            anchor.fillPatternRepeat('no-repeat');
            
             
            if (anchor.hasName('rotater')) {
              anchor.height(10);
              anchor.width(10);
              anchor.cornerRadius(10);
              anchor.fill('#ffffff')
            }
            if (anchor.hasName('top-left')) {
              anchor.fillPatternImage(imgBorderTopLeft)
              anchor.offsetY(4);
              anchor.offsetX(4);
            }
            if (anchor.hasName('top-right')) {
              anchor.fillPatternImage(imgBorderTopRight)
              anchor.fillPatternOffset({x: 5, y: 0})
              anchor.offsetY(4);
              anchor.offsetX(22);
            }
            if (anchor.hasName('bottom-left')) {
              anchor.fillPatternImage(imgBorderBottomLeft)
              anchor.fillPatternOffset({x: 0, y: 5})
              anchor.offsetY(22);
              anchor.offsetX(4);
            }
            if (anchor.hasName('bottom-right')) {
              anchor.fillPatternImage(imgBorderBottomRight)
              anchor.fillPatternOffset({x: 5, y: 5})
              anchor.offsetY(22);
              anchor.offsetX(22);
            }
          }
        });

        stikerNodes[i].added = !stikerNodes[i].added
        stikerNodes[i].node = node
        stikerNodes[i].transformer = transformer
        layer.add(transformer);
        layer.add(node);
      });
    }
  })
})

const saveBtn = document.querySelector('.editor__save')

saveBtn.addEventListener('click', () => {
  stikerNodes.forEach(item => {
    if (item.transformer) item.transformer.remove();
  })
  let dataURL = stage.toDataURL();
  let link = document.createElement('a');
  link.download = 'stage';
  link.href = dataURL;
  link.click();
  stikerNodes.forEach(item => {
    if (item.transformer) layer.add(item.transformer);
  })
});

};
export default editor;
